<template>   
   <HeaderDash />
   <SideMenu /> 
   <ContentScreenshot /> 
</template>

<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/manager/dashboard/HeaderDash'
import SideMenu from '@/components/manager/dashboard/SideMenu'
import ContentScreenshot from '@/components/manager/dashboard/modules/activity/screenshot/ContentScreenshot'
export default 
{
  name: 'Screenshot',
  components: 
  {
    HeaderDash,
    SideMenu,
    ContentScreenshot
  }
}
</script>

<style>


</style>
